<template lang="pug">
  v-row.login.align-stretch
    v-col.login-left.d-flex.align-center(cols="12" md="8")
      v-img.login-left-image.mx-auto(:src="logoSrc" max-width="50%" )
    v-col.login-right.d-flex.align-center(cols="12" md="4" v-if="!forgotPassword")
      form(@submit.prevent="loginAndRedirect")
        v-row.px-md-15
          v-col(cols="12")
            .font-weight-medium.text-color--dark1 Robo Administration
          v-col(cols="12" v-if="recoverPasswordSuccessMessage !== null")
            v-alert(type="success" text) {{ recoverPasswordSuccessMessage }}
          v-col(cols="12" v-if="accountBlocked")
            v-alert(type="error" text)
              | Your account has blocked because of too many login attempts.
              | Please contact with admins.
          v-col(cols="12" v-else-if="loginError")
            v-alert(type="error" text) Login failed
          v-col.py-1(cols="12")
            .text-color--dark1.body-2.font-weight-regular E-mail
            v-text-field(outlined dense hide-details v-model.trim="email")
          v-col.py-1(cols="12")
            div.d-flex.justify-space-between
              .text-color--dark1.body-2.font-weight-regular Password
            v-text-field(
              outlined
              dense
              hide-details
              :type="(show ? 'text': 'password')"
              v-model.trim="password"
            )
              template(#append v-if="show")
                eye-icon.cp(size="1.3x" @click="show = !show")
              template(#append v-else)
                eye-off-icon.cp(size="1.3x" @click="show = !show")
          v-col.py-1(cols="6")
            v-checkbox.ma-0.pa-0(hide-details v-model="rememberMe" color="#000138")
              template(v-slot:label)
                .fs-14.black--text Remember me
          v-col.py-1(cols="6", @click="forgotPassword = true")
            .fs-14.forgot-password.text-md-right Forgot password?
          v-col.py-1(cols="12")
            v-btn.white--text(
              block
              color="#000138"
              type="submit"
              :disabled="loginLoading"
            ) SIGN IN
    v-col.login-right.d-flex.align-center(cols="12" md="4" v-if="forgotPassword")
      form(@submit.prevent="handleForgotPassword")
        v-row.px-md-15
          v-col(cols="12" v-if="isMailSent && !sendPasswordRecoveryMailError")
            v-alert(type="success" text) {{ forgotPasswordMessages[1] }}
          v-col(cols="12" v-if="sendPasswordRecoveryMailError")
            v-alert(type="error" text) {{ sendPasswordRecoveryMailErrorMessage }}
          v-col(cols="12")
            .font-weight-medium.text-color--dark1 Magnus Forgot Password
          v-col(cols="12")
            v-row(@click="returnToLogin").forgot-password
              v-col(cols="1")
                v-icon(fill="#000138 !important") mdi-arrow-left
              v-col(cols="10")
                .font-weight-medium.text-color--dark1 Login 
          v-col(cols="12")
            .text-md-left {{ forgotPasswordMessages[0] }} 
          v-col.py-1(cols="12")
            .text-color--dark1.body-2.font-weight-regular E-mail
            v-text-field(outlined dense hide-details v-model.trim="forgotPasswordEmail")
          v-col.py-1(cols="12")
            v-btn.white--text(
              block
              color="#000138"
              type="submit"
              :disabled="sendPasswordRecoveryMailLoading"
            ) {{ !isMailSent ? 'Send' : 'Resend' }}
</template>
<script>
import logoSrc from '@/assets/image/magnusLogin.svg'
import {EyeIcon, EyeOffIcon} from 'vue-feather-icons'
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: 'Login',

  components: {
    EyeIcon,
    EyeOffIcon
  },

  data() {
    return {
      show: false,
      email: '',
      forgotPasswordEmail: '',
      password: '',
      logoSrc,
      rememberMe: false,
      forgotPassword: false,
      forgotPasswordMessages: [
        "Please enter your e-mail address to reset your password",
        "Password reset e-mail sent successfully",
      ],
      isMailSent: false,
    }
  },

  computed: {
    ...mapState("Auth", [
      "loginError",
      "accountBlocked",
      "loginLoading",
      "is2faEnabled",
      "sendPasswordRecoveryMailLoading",
      "sendPasswordRecoveryMailError",
      "sendPasswordRecoveryMailErrorMessage",
      "recoverPasswordSuccessMessage",
    ])
  },

  methods: {
    ...mapActions("Auth", [
      "login",
      "register2fa",
      "sendPasswordRecoveryMail",
    ]),

    async loginAndRedirect() {
      const result = await this.login({
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe
      });

      if (result) {
        if (!this.is2faEnabled) {
          const response = await this.register2fa({ email: this.email });
          if (response) this.$router.push("/register-2fa");
        }
        else this.$router.push("/verify-2fa");
      }
    },

    returnToLogin() {
      this.forgotPassword = false;
      this.isMailSent = false;
    },

    async handleForgotPassword() {
      const success = await this.sendPasswordRecoveryMail({
        email: this.forgotPasswordEmail,
      });
      this.isMailSent = success;
    }
  }
}
</script>

<style lang="sass" scoped>
.login
  height: 100vh

  &-left
    background-color: #000138

.forgot-password
  cursor: pointer
  color: #000138 
</style>
